import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import { FaCheck } from "react-icons/fa";
import Columns from "../components/columns";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Tile from "../components/Tile";
import Container from "../components/container";
import { Link } from "gatsby";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    aura: file(relativePath: { eq: "logo-aura.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    synergius: file(relativePath: { eq: "synergius-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    laptop: file(relativePath: { eq: "wsparcie-sprzedazy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const SalesAndMarketing = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Sales and marketing"
      headline="Improve your customer relationship management"
    >
      <SEO
        title="Sales and marketing. CRM systems - take care of customer relationships"
        description="Sales and marketing in the CRM system ✅ CRM systems for large, medium and small enterprises • Dedicated CRM • 
        Ready to use CRM • Simple CRM"
        keywords={["CRM systems"]}
      />

      <p>
        We know how important cooperation with clients is. We care for{" "}
        <strong>
          a high level of service and good relationships ourselves
        </strong>
        . It is thanks to the experience gained during cooperation with our
        clients that we have developed CRM systems that{" "}
        <strong>
          work well at various stages of the company's development
        </strong>
        . The solutions we offer and constantly improve include those for large,
        medium and small enterprises. How do we do it? When creating software,
        we <strong>focus on flexibility</strong>, which allows us to offer CRM
        systems ideal for companies with diverse needs.
      </p>
      <br />
      <br />
      <Columns>
        <div>
          <h3>Dedicated solution for large companies</h3>
          <p>
            <strong>Aura Business</strong> is a comprehensive solution that{" "}
            <strong>streamlines business processes</strong>. It is a completely
            flexible and complementary tool for ERP,{" "}
            <strong>supporting sales, marketing and service management</strong>.
            This is not only a CRM, because thanks to the great flexibility of
            the solution you can <strong>choose exactly what you need</strong>{" "}
            from the many possibilities we offer. If the need arises, we can
            also create completely new solutions that will support the
            development of your business.
          </p>
          <p>
            Do you have custom needs? Do you need integration with other
            systems? Do you run a large enterprise? Do you care about knowledge
            and experience? If so,{" "}
            <strong>Aura Business is the perfect solution for you</strong>. In
            addition to the solution tailored to your needs, we also offer{" "}
            <strong>professional support of our consultants</strong>. They are
            experts in project implementation and deployment of IT solutions in
            various fields. See how much we can do for your business!
          </p>
        </div>

        <div>
          <br />
          <br />
          <br />
          <br />
          <a href="https://aurabusiness.com/">
            <GatsbyImage
              image={data.aura.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 300,
              }}
              alt="Aura Business"
            />
          </a>
          <Container>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <a className={styles.startNow} href="https://aurabusiness.com/">
                Learn more about Aura Business
              </a>
            </div>
          </Container>
        </div>
      </Columns>
      <br />
      <br />

      <br />
      <br />

      <Columns>
        <div>
          <h3>System with ready-to-go integrations - Synergius CRM</h3>
          <p>
            <strong>Synergius CRM</strong> is an extensive CRM system that
            enables comprehensive sales service. It provides, among others, the
            ability to manage the sales and offering process, coordinate work
            and reporting. Of course it is also a coherent base of knowledge
            about your contractors and products. It also improves internal and
            external communication thanks to integration with the e-mail client
            and the built-in messenger.
          </p>
          <p>
            Its great advantage is the{" "}
            <strong>
              integration with sales and warehouse management systems
            </strong>{" "}
            that your company uses. It is a ready-to-go system that does not
            require a design approach.{" "}
            <strong>Numerous functions and configuration options</strong> are
            something that will provide you with convenient operation and
            improved sales!
          </p>
        </div>

        <div>
          <br />
          <br />
          <br />
          <br />
          <a href="https://synergiuscrm.pl/">
            <GatsbyImage
              image={data.synergius.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 300,
              }}
              alt="Synergius CRM"
            />
          </a>
          <Container>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <a className={styles.startNow} href="https://synergiuscrm.pl/">
                Learn more about Synergius CRM
              </a>
            </div>
          </Container>
        </div>
      </Columns>
      <br />
      <br />
      <center>
        <h4>
          Are you interested in our CRM systems? Contact us. We'd be happy to
          talk about the development opportunities for your business.
        </h4>
        <Container>
          <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/contact/">
              Contact us
            </Link>
          </div>
        </Container>
      </center>
    </FunctionPageLayout>
  );
};

export default SalesAndMarketing;
